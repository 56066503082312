import styled from "@emotion/styled";
const DESCRIPTION_MAX_WIDTH = 500;
const SOCIAL_PROOF_TEXT_MAX_WIDTH = 400;
const PARAGRAPH_HEIGHT = 150;
export const StyledParallexParagraphs = styled.div`
  .paragraph {
    .bold {
      font-weight: bold;
    }

    &:first-child {
      .headline {
        margin-top: 128px;
      }
    }

    .headline {
      margin-top: ${PARAGRAPH_HEIGHT}px;
      max-width: ${DESCRIPTION_MAX_WIDTH}px;
      .core-title-container .core-title {
        font-weight: 300;
      }
    }

    .social-proof {
      display: flex;
      margin-top: ${PARAGRAPH_HEIGHT}px;
      align-items: center;

      .avatar {
        width: 72px;
        height: 72px;
      }

      .testimonial {
        font-size: 0.875rem;
        line-height: 24px;
        margin-left: 16px;
        display: flex;

        .content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          max-width: ${SOCIAL_PROOF_TEXT_MAX_WIDTH}px;

          .company {
            font-size: 0.8125rem;
            line-height: 20px;
          }

          .person {
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 0.8125rem;
            line-height: 20px;

            .text {
              font-weight: bold;
            }

            .divider {
              margin: 0 4px;
            }

            .logo {
              width: auto;
              height: 18px;
            }
          }
        }
      }
    }
  }
`;
