import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { WHITE_COLOR_NAME } from "styles/color-consts";
import { SM } from "components/core/new-button/button-component";
export const defaultParagraphs = [
    {
        title: "Streamline your work for<br/><b>maximum productivity</b>",
        description: "Centralize all your work, processes, tools, and files into one Work OS. Connect teams, bridge silos, and maintain one source of truth across your organization.",
        avatarSrc: "https://dapulse-res.cloudinary.com/image/upload/r_400/f_auto,q_auto/remote_mondaycom_static/uploads/BarBen/jane.jpeg",
        avatarAlt: "Jane Tham's profile image",
        testimonial: "We use monday.com for a plethora of use cases, the opportunities this platform provides are limitless.",
        name: "Jane Tham",
        jobTitle: "VP of Collaboration Technologies",
        companyName: "Universal Music Group"
    },
    {
        title: "Bring teams together to<br/><b>drive business impact</b>",
        description: "Collaborate effectively organization-wide to get a clear picture of all your work. Stay in the loop with easy-to-use automations and real-time notifications.",
        avatarSrc: "https://dapulse-res.cloudinary.com/image/upload/r_400/f_auto,q_auto/remote_mondaycom_static/uploads/BarBen/sarah.jpeg",
        avatarAlt: "Sarah Pharr's profile image",
        testimonial: "Since adopting monday.com, our global marketing department has seen a 40% improvement in cross-team collaboration.",
        name: "Sarah Pharr",
        jobTitle: "AVP Marketing",
        companyName: "Genpact",
        buttonConfig: {
            buttonType: SIGNUP_BUTTON_TYPE,
            buttonColor: WHITE_COLOR_NAME,
            invert: true,
            buttonSize: SM
        }
    },
    {
        title: "Stay on track to<br/><b>reach your goals, faster</b>",
        description: "Get a high-level overview of your organization with customizable dashboards. Make confident decisions and easily scale workflows for your evolving needs.",
        avatarSrc: "https://dapulse-res.cloudinary.com/image/upload/r_400/f_auto,q_auto/remote_mondaycom_static/uploads/BarBen/baptiste.jpeg",
        avatarAlt: "Baptiste Ancey's profile image",
        testimonial: "monday.com allows banks to be synchronized between the top-level management figures and local KPIs.",
        name: "Baptiste Ancey",
        jobTitle: "Head of Innovation",
        companyName: "Indosuez Wealth Management"
    }
];
export default {
    alignToLogo: true,
    paragraphs: defaultParagraphs,
    topAsset: {
        src: "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/first_board.png",
        alt: "monday.com board with multiple integrations"
    },
    bottomAsset: {
        src: {
            formats: [
                "/Generator_featured images/Home Page - 2022 Rebrand/parallax/goals_dashboard.mp4"
            ]
        }
    }
};
