import styled from "@emotion/styled";
import { SPACE_BLUE_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
import { sharedComponentStyles } from "../scroll-parallax-homepage-first-fold-component.scss";
export const StyledScrollParallaxHomepageFirstFoldMobileComponent = styled.div`
  ${sharedComponentStyles}
  background: ${colors[SPACE_BLUE_COLOR_NAME]};
  .full-paragraph-component-wrapper {
    padding-bottom: 20px;
  }

  .middle-paragraph-and-asset {
    padding-bottom: 24px;
  }

  .top-asset-wrapper {
    .asset-inner {
      padding-top: 72px;
    }
  }

  .top-asset-wrapper,
  .bottom-asset-wrapper {
    .parallax-galaxy-wrapper {
      transform: scale(0.4);
      top: 5vw;
      right: -28vw;
      position: relative;
    }
  }

  .middle-asset-wrapper {
    width: 100%;
    min-height: 250px;
    .typing-wrapper {
      display: flex;
      margin-top: 24px;
      .picture-component {
        padding-right: 8px;
        img {
          width: 40px;
        }
      }
    }
    .typing-wrapper:last-child {
      .typed-component {
        display: flex;
      }
    }
  }

  .side-by-side-text-and-asset {
    .side-by-side-content.is-full-width {
      padding-top: 60px;
      padding-bottom: 0;
    }
  }
`;
