import styled from "@emotion/styled";
export const StylesTypedComponent = styled.div`
  .content {
    white-space: pre;
    font-size: 0.875rem;

    .tag {
      color: #0981fc;
    }
  }
`;
