function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { useEffect, useCallback, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";
const useRect = (ref)=>{
    const [rect, setRect] = useState(getRect(ref ? ref.current : null));
    const updateRect = useCallback(()=>{
        if (!ref.current) return;
        // Update client rect
        setRect(getRect(ref.current));
    }, [
        ref
    ]);
    useEffect(()=>{
        const element = ref.current;
        if (!element) return;
        updateRect();
        let resizeObserver = new ResizeObserver(()=>updateRect());
        resizeObserver.observe(element);
        return ()=>{
            if (!resizeObserver) return;
            resizeObserver.disconnect();
            resizeObserver = null;
        };
    }, [
        ref.current
    ]);
    useEffect(()=>{
        window.addEventListener("scroll", updateRect);
        return ()=>{
            window.removeEventListener("scroll", updateRect);
        };
    }, [
        ref.current
    ]);
    return rect;
};
function getRect(element) {
    if (!element) {
        return {
            height: 0,
            width: 0,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            clientHeight: 0,
            clientWidth: 0,
            offsetTop: 0,
            offsetBottom: 0,
            offsetLeft: 0,
            offsetRight: 0
        };
    }
    const { clientHeight, clientWidth, offsetTop, offsetBottom, offsetLeft, offsetRight } = element;
    return _object_spread_props(_object_spread({}, element.getBoundingClientRect()), {
        clientHeight,
        clientWidth,
        offsetTop,
        offsetBottom,
        offsetLeft,
        offsetRight
    });
}
export default useRect;
