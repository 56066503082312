import { useEffect, useState } from "react";
import { isServerSide } from "utils/general";
function getSize() {
    var _window, _document_documentElement, _document, _document_body, _document1, _window1, _document_documentElement1, _document2, _document_body1, _document3;
    if (isServerSide()) {
        return {
            width: undefined,
            height: undefined
        };
    }
    return {
        width: ((_window = window) === null || _window === void 0 ? void 0 : _window.innerWidth) || ((_document = document) === null || _document === void 0 ? void 0 : (_document_documentElement = _document.documentElement) === null || _document_documentElement === void 0 ? void 0 : _document_documentElement.clientWidth) || ((_document1 = document) === null || _document1 === void 0 ? void 0 : (_document_body = _document1.body) === null || _document_body === void 0 ? void 0 : _document_body.clientWidth),
        height: ((_window1 = window) === null || _window1 === void 0 ? void 0 : _window1.innerHeight) || ((_document2 = document) === null || _document2 === void 0 ? void 0 : (_document_documentElement1 = _document2.documentElement) === null || _document_documentElement1 === void 0 ? void 0 : _document_documentElement1.clientHeight) || ((_document3 = document) === null || _document3 === void 0 ? void 0 : (_document_body1 = _document3.body) === null || _document_body1 === void 0 ? void 0 : _document_body1.clientHeight)
    };
}
export default function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getSize());
    useEffect(()=>{
        if (isServerSide()) {
            return false;
        }
        function handleResize() {
            setWindowSize(getSize());
        }
        window.addEventListener("resize", handleResize);
        return ()=>window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}
