import { MD_DESKTOP_WIDTH } from "constants/vp-sizes";
import styled from "@emotion/styled";
export const StyledParallaxGalaxy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -11vw;
    top: -4vw;
    opacity: 1;

    .galaxy-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 500px;
      height: 500px;
      margin: 0 auto;
      position: relative;
    }

    .galaxy-container .spin-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 448px;
      height: 448px;
    }

    /* circles backgrounds */
    .galaxy-container .spin-container .circle-container {
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      animation: rotate-circle var(--d) linear infinite;
    }

    .galaxy-container .spin-container .circle-container.small-circle {
      width: 200px;
      height: 200px;
      background-image: url(https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/Ellipse3.svg);
    }

    .galaxy-container .spin-container .circle-container.medium-circle {
      --d: 6s; /* duration */
      width: 270px;
      height: 270px;
      background-image: url(https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/Ellipse2.svg);
    }

    .galaxy-container .spin-container .circle-container.large-circle {
      --d: 15s; /* duration */
      width: 350px;
      height: 350px;
      background-image: url(https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/Ellipse1.svg);
    }

    /* floating items */
    .galaxy-container .spin-container .items-container {
      position: absolute;
      display: grid;
      place-content: center;
    }

    .galaxy-container .spin-container .items-container.small-circle {
      --n: 3; /* number of item */
      --d: 9s; /* duration */
      width: 200px;
      height: 200px;
    }

    .galaxy-container .spin-container .items-container.medium-circle {
      --n: 2; /* number of item */
      --d: 14s; /* duration */
      width: 270px;
      height: 270px;
    }

    .galaxy-container .spin-container .items-container.large-circle {
      --n: 6; /* number of item */
      --d: 25s; /* duration */
      width: 350px;
      height: 350px;
    }

    .item {
      grid-area: 1/1;
    }

    /* small circle items */
    .galaxy-container .spin-container .items-container.small-circle .item {
      width: auto;
      height: 68px;
      animation: small-spin var(--d) linear infinite;
      transform: rotate(0) translate(95px) rotate(0);
    }

    .galaxy-container
    .spin-container
    .items-container.small-circle
    .item:nth-child(1) {
      animation-delay: calc(-0 * var(--d) / var(--n));
    }

    .galaxy-container
    .spin-container
    .items-container.small-circle
    .item:nth-child(2) {
      animation-delay: calc(-1 * var(--d) / var(--n));
    }

    .galaxy-container
    .spin-container
    .items-container.small-circle
    .item:nth-child(3) {
      animation-delay: calc(-2 * var(--d) / var(--n));
    }

    .galaxy-container .spin-container .items-container.small-circle .item img {
      width: auto;
      height: 68px;
    }

    /* medium circle items */
    .galaxy-container .spin-container .items-container.medium-circle .item {
      width: auto;
      height: 62px;
    }

    .galaxy-container
    .spin-container
    .items-container.medium-circle
    .item.item-inner {
      animation: medium-spin-inner var(--d) linear infinite;
      transform: rotate(0) translate(135px) rotate(0);
    }

    .galaxy-container
    .spin-container
    .items-container.medium-circle
    .item.item-outer {
      animation: medium-spin-outer var(--d) linear infinite;
      transform: rotate(0) translate(175px) rotate(0);
    }

    .galaxy-container
    .spin-container
    .items-container.medium-circle
    .item:nth-child(1) {
      animation-delay: calc(-0 * var(--d) / var(--n));
    }

    .galaxy-container
    .spin-container
    .items-container.medium-circle
    .item:nth-child(2) {
      animation-delay: calc(
              -1 * var(--d) / (var(--n) * 2)
      ); /* multiple --n for positioning of items */
    }

    .galaxy-container .spin-container .items-container.medium-circle .item img {
      width: auto;
      height: 62px;
    }

    /* large circle items */
    .galaxy-container .spin-container .items-container.large-circle .item {
      width: auto;
      height: 50px;
      animation: large-spin var(--d) linear infinite;
      transform: rotate(0) translate(225px) rotate(0);
    }

    /* .galaxy-container .spin-container .items-container.large-circle .item.item-inner{
      animation: large-spin-inner var(--d) linear infinite;
      transform: rotate(0) translate(195px) rotate(0);
    }
    
    .galaxy-container .spin-container .items-container.large-circle .item.item-outer{
      animation: large-spin-outer var(--d) linear infinite;
      transform: rotate(0) translate(210px) rotate(0);
    } */

    .galaxy-container
    .spin-container
    .items-container.large-circle
    .item:nth-child(1) {
      animation-delay: calc(-0 * var(--d) / var(--n));
    }

    .galaxy-container
    .spin-container
    .items-container.large-circle
    .item:nth-child(2) {
      animation-delay: calc(-1 * var(--d) / (var(--n)));
    }

    .galaxy-container
    .spin-container
    .items-container.large-circle
    .item:nth-child(3) {
      animation-delay: calc(-1.5 * var(--d) / (var(--n)));
    }

    .galaxy-container
    .spin-container
    .items-container.large-circle
    .item:nth-child(4) {
      animation-delay: calc(-3 * var(--d) / (var(--n)));
    }

    .galaxy-container
    .spin-container
    .items-container.large-circle
    .item:nth-child(5) {
      animation-delay: calc(-4 * var(--d) / (var(--n)));
    }

    .galaxy-container .spin-container .items-container.large-circle .item img {
      width: auto;
      height: 50px;
    }

    /* keyframes */
    @keyframes small-spin {
      100% {
        transform: rotate(1turn) translate(95px) rotate(-1turn);
      }
    }
    @keyframes medium-spin-inner {
      100% {
        transform: rotate(1turn) translate(135px) rotate(-1turn);
      }
    }
    @keyframes medium-spin-outer {
      100% {
        transform: rotate(1turn) translate(175px) rotate(-1turn);
      }
    }
    @keyframes large-spin {
      100% {
        transform: rotate(1turn) translate(225px) rotate(-1turn);
      }
    }
    /* @keyframes large-spin-outer {
      100% {
        transform: rotate(1turn) translate(210px) rotate(-1turn);
      }
    } */
    @keyframes rotate-circle {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }

    /* twinkling stars */

    #twinkling-stars {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      .star {
        --twinkle-duration: "";
        --twinkle-delay: "";
        --star-size: "";
        position: absolute;
        background-image: url("https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/parallax/integrations/star.svg");
        background-repeat: no-repeat;
        background-size: cover;
        width: var(--star-size);
        height: var(--star-size);
        /* background: #fff; */
        border-radius: 50%;
        animation: twinkle infinite alternate;
        animation-duration: var(--twinkle-duration);
        animation-delay: var(--twinkle-delay);
        will-change: transform;
      }
    }

    .circle {
      background-image: unset;
      background: #fff;
    }

    @keyframes twinkle {
      from {
        transform: scale(0);
      }
      to {
        transform: scale(1.5);
        /* box-shadow: inset 0 0 5px 0.5px rgba(255, 255, 255, 0.6); */
      }
    }
  }
  @media (max-width: ${MD_DESKTOP_WIDTH}) {
      right: -33vw;
      top: -2vw;
    }
`;
